<template>
  <div class="layout">
    <div class="max-w-md w-full">
      <slot />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.layout {
  @apply flex flex-col justify-center items-center p-2
}
</style>
